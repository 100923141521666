
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css');
@import url('https://unpkg.com/swiper/swiper-bundle.min.css');
@import "const";



// common style modifier ----------------------------
html {
  scroll-behavior: smooth;
}

body {
      font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
      font-size: 11px;
      color: #777;
}

html[lang="zh"]{
     body {
          font-size : 14px;
     }
}
html[lang="zh-Hans"]{
     body {
          font-size : 14px;
     }
}

h1,h2,h3,h4,h5,h6 {
     margin-bottom: 1rem;
     line-height: 1.2em;
}


h1 { font-weight: 700; text-transform: uppercase; }

h6, h4 {
     letter-spacing: 0.15rem;
     text-transform: uppercase;
}
h6 {
    font-weight: bold;
}

img {width:100%; }
a, a:hover, a:active, a:active {
     text-decoration: none;
     color: inherit;
}


.text-white { color: #ffffff;}
.text-green { color: $color-green-dark;}
.text-green-dark { color: $color-green-dark2;}
.text-brown {color: $color-brown-dark;}

.text-size-bigger { font-size: 1.5rem;  }

.text-bold {font-weight: bold;}

.text-block {
     background-color: $color-grey-dark;
     display: inline-block;
     padding: 3px 10px;
     color: #FFFFFF;
}

.bg-grey-light {background-color: $color-grey-light;}
.bg-grey {background-color: $color-grey-dark;}

.bg-blue  { background-color: $color-blue-light;}
.bg-navy  { background-color: $color-navy-light;}

.bg-green { background-color: $color-green-dark;}
.bg-green-light { background-color: $color-green-light;}
.bg-beige  { background-color: $color-yellow-light;}

.bg-beige-light  { background-color: $color-beige-light;}
.bg-beige-dark {background-color: $color-beige-dark;}

.bg-investor, .bg-pink {background-color: $color-pink-light; }
.bg-csr, .bg-purple {background-color: $color-purple-dark; }

.bg-brown-light  { background-color: $color-brown-light;}
.bg-products, .bg-brown {background-color: $color-brown-dark; }

.bg-white { background-color: #FFFFFF;}

.hide     {       }
.center {text-align: center;}
.spacer-top-bottom { padding-top: 20px; padding-bottom: 20px;}
.spacer-buffer {padding:20px;}
.spacer-buffer-50 {padding:50px;}

.smooth-ani {
     -webkit-transition: all $ani-speed $ani-easing;
      -moz-transition: all $ani-speed $ani-easing;
      -ms-transition: all $ani-speed $ani-easing;
      -o-transition: all $ani-speed $ani-easing;
      transition: all $ani-speed $ani-easing;
}


.btn {
     padding : 10px 30px;
     border: 1px solid #fff;
     margin: 5px;
}



// theme lauyout ===========================================

.layout-canvas {
     margin: 0 auto;
     width: 100%;
     // max-width: $hd-size;
     min-height: 100vh;
}

.layout-2-cols-left {
     padding:20px 55% 20px 30px;
}

.layout-2-cols-image-right {
     position: absolute;
     width: 55%;
     overflow: hidden;
     margin-top:5%;
     right: 0;
}

.layout-1-cols-image {
     position: absolute;
     margin-top: -50px;
     right: 0px;
     width: 70%;
     max-width: $tablet-size;
}

// specific for company profile
.layout-margin-limiter {
     margin-top: min(40%, 450px);
}

// reduce the size for the grpahic for table
.chart-table {
     img {
          margin: 20px 0;
          width: auto; height: 150px;
     }
}

.layout-text-vertical-center {
     display: flex;
     align-items: center;
     height: 100%;
     padding: 20px;
}

.layout-image-frame {
     overflow: hidden;
}

.layout-image-border {
     overflow: hidden;
}

.grid {
     display : grid;
}

.grid-gutter {
     grid-gap: 10px;
}

/*
.grid-2-cols {
     grid-template-columns: 1fr 1fr;
     align-items: center;
     div {
          display: flex;
          flex-direction: column;
          align-items: center;
     }
}
*/

.grid-4-cols {
     grid-template-columns: 1fr 1fr 1fr 1fr;
     align-items: center;
     div {
          display: flex;
          align-items: center;
          min-height: calc(100vw / 4);
     }
}

// swiper
.swiper-container {
    width: 100%;

     .swiper-button-next, .swiper-button-prev {
          opacity: 0.5;
          color: #000;
     }
}

// header ===============

header {
     .header-logo img {
          position: absolute;
          top: 0px;
          width : $corner-logo-width;
          height: auto;
     }

     .mobile-menu {
          display: none;
          position: absolute;
          left: 0;
          width: 100%;
          height: 100vh;
          overflow: hidden;
          .menu-handler {
               position: fixed;
               right: 0px;
               top: 0px;
               width: 35px;
               height: 35px;
               background-color: #FFF;
               z-index: 30000;
               padding: 15px;

               hr {
                    border : 1px solid $color-blue-dark;
                    margin-top: 10px;
                    margin-bottom: 0px;
               }
               hr:first-child {
                    margin-top: 5px;
               }
          }

          .menu-handler.clicked {
               hr:first-child {
                    margin-top:15px;
                    -moz-transform:rotate(45deg);
                    -webkit-transform:rotate(45deg);
                    -o-transform:rotate(45deg);
                    -ms-transform:rotate(45deg);
                    transform:rotate(45deg);
               }
               hr:last-child {
                    margin-top:-14px;
                    -moz-transform:rotate(-45deg);
                    -webkit-transform:rotate(-45deg);
                    -o-transform:rotate(-45deg);
                    -ms-transform:rotate(-45deg);
                    transform:rotate(-45deg);
               }

               hr:nth-child(2) {
                    opacity: 0;
               }
          }

          .menu-bg {
               position: fixed;
               top:0px;
               left:0px;
               z-index: 12000;
               width: 100%;
               height: 100%;
               background: rgba(#131950, 0.95);
          }
          .menu-bg.hide{
               left: 100vw;
          }

          .nav-menu {
               margin-top: 100px;
               position: fixed;
               z-index: 12100;
               left:0px;
               li {
                    display: block;
                    width:100vw;
                    text-align: center;
                    font-size: 2rem;
                    color: #fff;
                    margin-bottom: 20px;
               }

               // lanuage
               li:last-child {
                    margin-top: 40px;
                    font-weight: 100;
                    font-size: 1.5rem;
                    a {
                         padding: 5px 8px;
                         border: 1px solid white;
                         margin: 0px 5px;
                    }

               }

          }

          .nav-menu.hide {
               left: 100vw;
          }

     }

     .header-menu {
          .nav-menu {
               width:100%;
               text-align: right;
               //float: right;
               ul {
                    margin: 0px;
               }

               li {
                    background-color: #FFFFFF;
                    display: inline-block;
                    margin: 0px;
                    padding: 20px 15px;
               }

               li:first-child {
                    background-color: #FFFFFF;
                     padding-left: 30px;
                     a {
                         margin-left: 0px;
                     }
                }

               li.menuitem-language {
                    color: #ffffff;
                    text-align: center;
                    background-color: $color-blue-dark;
                    padding-right: 20px;
                    padding-left: 20px;
                    a {
                        margin-left: 5px;
                    }
               }
          }

     }
}



// footer ===============

footer {

     //margin-top: 15vh;
     width: 100%;
     min-height: 200px;
     background-color: $color-blue-dark;
     text-align: center;
     color: #ccc;
     z-index: 9000;
     .footer-menu {
          padding-top:20px;
          margin-bottom: 20px;
          li {
               display: inline-block;
               padding: 5px 30px;
               font-weight: 100;

          }
     }

     .footer-disclaimer {
          color: #fff;
          font-weight: 300;
     }
}


// sections

section#keyvisual {

     margin-bottom: 0px;
}



section {

     .gfx-block-white {
               width: 100%;
               z-index: 10;
               height: 10vh;
               margin-top: -10vh;
               position: absolute;
               background-color: #fff;
     }

     width: 100%;
     .banner-hero {
          width: calc(100% - #{$corner-logo-width} - #{$buffer-gutter});
          position: relative;
          z-index: 100;
          max-height: 750px;
          min-height: 600px;
          height: 100%;
          margin-left: calc(#{$corner-logo-width} + #{$buffer-gutter});
          overflow: hidden;
          img {
               min-width: $hd-size;
               margin-left: 0px;
               width: 100%;
          }
     }

     .banner-hero-text {
          z-index: 1000;
          position: absolute;
          max-width: 500px;
          min-width : 250px;
          margin-left: $corner-logo-width;
          top : calc(#{$corner-logo-width} + #{$buffer-gutter});
          h1 {
               margin-bottom: 10px;
               line-height: 1em;
          }
     }

     .gfx-elem {
          position: relative;
          height: 0px;
          width : 100%;
          z-index: 350;
     }

     .elem-1 {
          position: absolute;
          right: 0px;
          width: 25vw;
          min-width: 250px;
          img { width: 100%; }
     }

     .elem-2 {
          margin-top: 50px;
          img {width: 200px; }
     }

     .elem-3 {
          top: -150px;
          left: - 250px;
          img {width: 200px; }
     }

     .local-url-tag {
          display: inline-block;
          padding: 3px 10px;
          background-color: $color-blue-dark;
          font-weight: 300;
          text-transform: uppercase;
     }
}







// Responsive ==========================

@media (max-width : $desktop-size) {
     header {
          .mobile-menu {
               display: block;
          }
          .header-menu {
               .nav-menu {
                    li {   display: none;    }
               }
          }
     }
}



@media (max-width : $tablet-size) {}

@media (max-width : $phablet-size) {

     p {
          font-size: 1.65rem;
     }

     header {
          .header-logo img {
               width : calc(#{$corner-logo-width} / #{$sr-sizing-factor});
          }
     }



     section {
          .banner-hero {
               width: calc(100% - #{$buffer-gutter} /2);
               // min-height: 60px;
               height: 60vh;
               max-height: unset;
               margin-left: calc(#{$buffer-gutter} /2);
               img {
                    margin-top : calc((#{$corner-logo-width} / #{$sr-sizing-factor}) + (#{$buffer-gutter}/2));
                    margin-left: -120%;
                    // height: 100%;
                    height: unset;
                    min-width: unset;
                    width: 200%;

               }
          }

          .banner-hero-text {
               //max-width: 500px;
               //min-width : 250px;
               top: calc((#{$corner-logo-width} / #{$sr-sizing-factor}) + (#{$buffer-gutter}));
               margin-left: $buffer-gutter;
               padding-right : calc(#{$buffer-gutter} /3);
               h1 {
                    font-size: 3.5rem;
               }
          }

          .elem-1 {
               right: 65px;
               margin-top:65px;
               width: 30vw;
               min-width: 50px;
          }

          .elem-2 {
               margin-top: 50px;
               margin-left: -65px;
               img {width: 200px; }
          }
     }

     footer {
          .footer-menu {
               ul {
                    display: none;
               }
          }
     }
}

@media (max-width : $mobile-size) {

}
