// size definition ==========

$hd-size : 1180px;
$desktop-size : 960px;
$tablet-size : 780px;
$phablet-size : 560px;
$mobile-size : 400px;


// global background-color

$color-blue-light : #c5cfd9;
$color-blue-dark : #1f437d;

$color-navy-light: #c0d2df;

$color-grey-light : #d2d2d2;
$color-grey-dark : #5e5e5e;

$color-purple-light : #d5b8c8;
$color-purple-dark : #d5b8c8;

$color-pink-light : #dfc5c0;

$color-yellow-light : #d4cda9;

$color-green-light: #daedd8;
$color-green-dark: #b0ca8e;
$color-green-dark2: #3d5819;

$color-beige-light: #fdf2e7;
$color-beige-dark: #f7d4b0;

$color-brown-dark : #caaa8e;
$color-brown-light: #f0eee2;

// common layout measurement

     $corner-logo-width : 180px;
     $buffer-gutter : 80px;
     $sr-sizing-factor : 1.5;
     $ani-speed : 0.5s;
     $ani-easing : cubic-bezier(0.625, 0.070, 0.320, 0.945);
